import { strings } from "res";
import { DashboardIcon, OrderIcon, ReportIcon } from "../assets/svg/icon";
import {
  UserOutlined,
  TagOutlined,
  BranchesOutlined,
  FileOutlined,
  MessageFilled
} from "@ant-design/icons";

const dashBoardNavTree = [
  {
    key: "Home",
    path: strings.navigation.path.dashboard,
    title: "Home",
    breadcrumb: false,
    submenu: [
      {
        key: "Dashboard",
        path: strings.navigation.path.dashboard,
        title: "Dashboard",
        icon: DashboardIcon,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "Messages",
      //   path: strings.navigation.path.messages,
      //   title: "Messages",
      //   icon: MessageFilled,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "Anggota Dewan",
        path: strings.navigation.path.contents,
        title: "Anggota Dewan",
        icon: ReportIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Berita",
        path: strings.navigation.path.contents,
        title: "Berita",
        icon: ReportIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Kategori",
        path: strings.navigation.path.categories,
        title: "Kategori",
        icon: OrderIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Sub Kategori",
        path: strings.navigation.path.subcategories,
        title: "Sub Kategori",
        icon: BranchesOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Tags",
        path: strings.navigation.path.tags,
        title: "Tags",
        icon: TagOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Pengguna",
        path: strings.navigation.path.users,
        title: "Pengguna",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

// const ControlTree = [{
//   key: "Pengaturan",
//   path: strings.navigation.path.settings,
//   title: "Pengaturan",
//   breadcrumb: false,
//   submenu: [
//     {
//       key: "Pengaturan",
//       path: strings.navigation.path.settings,
//       title: "Pengaturan",
//       icon: SettingIcon,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [
  ...dashBoardNavTree,
  // ...ControlTree,
];

export default navigationConfig;
