import { Col, DatePicker, Row, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "antd";
import {
  fetchOneMessage,
} from "redux/features/messages";
import { MyEditor } from "../../../components/Editor";
import ImageUploader from "components/shared-components/ImageUploader";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

const selectStyle = {
  width: "100%",
  backgroundColor: "white",
};

const rules = [
  {
    required: true,
    message: "Wajib Diisi!",
  },
];

export const ADD_CONTENT = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneMessage(id)).unwrap();
      form.setFieldsValue({
        ...data.data,
        title: data.data.title
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id);
    }
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Add/Update Message</h2>
          <p>Please fill the message</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input your title!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="no_phone"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Status"
                name="status"
              >
                <Select options={[{
                  label:"OPEN",
                  value:"OPEN"
                },{
                  label:"CLOSED",
                  value:"CLOSED"
                }]} />
              </Form.Item>


              <Form.Item
                label="Description"
                name="description"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Message"
                name="message"
              >
                <TextArea rows={5} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Reply
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ADD_CONTENT;
